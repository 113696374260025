
/* eslint-disable @typescript-eslint/camelcase */
import { ErrorMessages } from '@/helpers/constants'
import { Product } from '@/interfaces/product.interface'
import { CreateTicketData } from '@/interfaces/ticket.interface'
import {
  editTicket,
  generateTicket,
  getProductInformation,
} from '@/services/seller.service'
import { generateQr } from '@/services/mp.service'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Validate } from 'vuelidate-property-decorators'
import { required } from 'vuelidate/lib/validators'
import PrincipalContactForm from '@/views/forms/principalContactForm.vue'
import InvitadoContactForm from '@/views/forms/invitadoForm.vue'

@Component({
  components: {
    PrincipalContactForm,
    InvitadoContactForm,
  },
})
export default class QRStatus extends Vue {
  public product?: Product | {} = {}
  public ticketId?: number
  public isEdit = false
  public i = 1
  public arrayId = 0
  public addInvitado = [this.i]
  public principalValidation = false
  public invitadoValidation = false
  public invitadoForm = []
  public principalForm!: object

  public id = this.$route.params.productId

  @Validate({ required }) reference!: string
  @Validate({ required }) peopleQuantity!: number
  @Validate({ required }) productStatus!: string
  @Validate({ required }) productPayment!: string

  @Prop() readonly headerText!: string

  arrayid(indice) {
    return indice
  }

  async addInvitados() {
    this.i = this.i + 1
    this.addInvitado.push(this.i)
  }

  async subInvitados(index) {
    this.invitadoForm.splice(index, 1)

    document.getElementById(`${index}`).remove()
  }

  validatePrincipal(validate) {
    this.principalValidation = validate
  }

  principalData(principal) {
    this.principalForm = principal
  }

  validate(validate) {
    this.invitadoValidation = validate
  }

  invitadoData(invitado) {
    const found = this.invitadoForm.find(e => e.id == invitado.id)
    if (found) {
      const index = this.invitadoForm.indexOf(found)
      this.invitadoForm[index] = invitado
    } else {
      this.invitadoForm.push(invitado)
    }
  }

  async createQr() {
    const loading = this.$loading.show()

    const qrData = {
      principal_contact: this.principalForm,
      guests: this.invitadoForm,
      product_id: (this.product as Product).id,
    }

    try {
      await generateQr(qrData).then((res: any) => {
        this.$router.replace({ path: `/seller/links/${res.data.data.uuid}` })
      })
    } catch (error) {
      console.log(Object.values(error.response.data.errors)[0][0])
      Vue.$toast.warning(Object.values(error.response.data.errors)[0][0])
    }
    loading.hide()
  }

  async createTicket() {
    const loading = this.$loading.show()
    try {
      let newTicket
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const productPaymentMethods: any = {
        Efectivo: 'cash',
        Transferencia: 'transfer',
        Tarjeta: 'card',
      }
      const createTicketData: CreateTicketData = {
        product_id: (this.product as Product).id,
        quantity_persons: this.peopleQuantity,
        payment_status: this.productStatus === 'Pagado' ? 1 : 0,
        payment_method: productPaymentMethods[this.productPayment],
        reference: this.reference,
      }

      if (this.isEdit && this.ticketId) {
        newTicket = await editTicket(createTicketData, this.ticketId)
      } else {
        newTicket = await generateTicket(createTicketData)
      }
      loading.hide()

      this.$router.push({
        name: 'Compartir Link',
        params: { productId: newTicket.uuid },
      })
      Vue.$toast.success(`Ticket editado correctamente!`)
    } catch (error) {
      if (error.response.status === 422) {
        Vue.$toast.warning(error.response.data.message)
        loading.hide()
        return
      }
      Vue.$toast.error(ErrorMessages.COMMON)
      loading.hide()
    }
  }

  async getProductInfo() {
    const loading = this.$loading.show()
    try {
      const { data } = await getProductInformation(this.$route.params.productId)
      this.product = data
      loading.hide()
    } catch (error) {
      Vue.$toast.error(ErrorMessages.COMMON)
      loading.hide()
    }
  }

  mounted() {
    this.getProductInfo()

    const {
      reference,
      edit,
      productStatus,
      productPayment,
      quota,
      ticketId,
    } = this.$route.query
    this.isEdit = edit == 'true' ? true : false

    //this.productStatus = productStatus == "true" ? "Pagado" : "Por pagar";
    this.productStatus = 'Pagado'
    this.productPayment = 'Transferencia'
    this.reference = reference as string
    this.reference = reference as string

    this.peopleQuantity = parseInt(quota as string)

    this.productPayment =
      (productPayment as string).charAt(0).toUpperCase() +
      (productPayment as string).slice(1)

    if (this.productPayment === 'Cash') {
      this.productPayment = 'Efectivo'
    } else if (this.productPayment === 'Transfer') {
      this.productPayment = 'Transferencia'
    } else if (this.productPayment === 'Card') {
      this.productPayment = 'Tarjeta'
    }

    this.ticketId = parseInt(ticketId as string)
  }

  get referenceErrors(): Array<string> {
    const errors: Array<string> = []
    if (!this.$v.reference.$dirty) return errors
    !this.$v.reference.required &&
      errors.push(ErrorMessages.COMMOM_FIELD_REQUIRED)
    return errors
  }
  get peopleQuantityErrors(): Array<string> {
    const errors: Array<string> = []
    if (!this.$v.peopleQuantity.$dirty) return errors
    !this.$v.peopleQuantity.required &&
      errors.push(ErrorMessages.COMMOM_FIELD_REQUIRED)
    return errors
  }
  get productStatusErrors(): Array<string> {
    const errors: Array<string> = []
    if (!this.$v.productStatus.$dirty) return errors
    !this.$v.productStatus.required &&
      errors.push(ErrorMessages.COMMOM_FIELD_REQUIRED)
    return errors
  }
  get productPaymentErrors(): Array<string> {
    const errors: Array<string> = []
    if (!this.$v.productPayment.$dirty) return errors
    !this.$v.productPayment.required &&
      errors.push(ErrorMessages.COMMOM_FIELD_REQUIRED)
    return errors
  }
}
