
import { Component, Vue } from 'vue-property-decorator'
import { Validate } from 'vuelidate-property-decorators'
import { required } from 'vuelidate/lib/validators'
import { ErrorMessages } from '@/helpers/constants'
import { getUserByDni } from '@/services/seller.service'

@Component
export default class PrincipalContactForm extends Vue {
  @Validate({ required }) nombreContacto: string
  @Validate({ required }) dni!: string
  @Validate({ required }) sexo!: string
  searchDni: string = ''
  usersArray = []
  mounted() {
    this.sexo = 'Otro'
  }

  async searchContact() {
    if (this.dni.length >= 4) {
      const contact = await getUserByDni(this.dni)
      this.usersArray = contact.data.assistants
    }

    if (this.dni.length <= 3) {
      this.usersArray = []
    }
  }

  rellenarContacto(user) {
    this.$v.nombreContacto.$model = user.fullname
    this.dni = user.dni

    switch (user.gender) {
      case 'male':
        this.sexo = 'Masculino'
        break

      case 'female':
        this.sexo = 'Femenino'
        break

      case 'others':
        this.sexo = 'Otros'
        break

      default:
        break
    }

    this.usersArray = []

    this.validate()
  }

  async createQr() {
    const contactForm = {
      contactName: this.nombreContacto,
      dni: this.dni,
      sexo: this.sexo,
    }
  }

  validate() {
    if (!this.nombreContacto || !this.dni || !this.sexo) {
      this.$emit('validatePrincipal', false)
    } else {
      let gender

      switch (this.sexo) {
        case 'Masculino':
          gender = 'male'
          break

        case 'Femenino':
          gender = 'female'
          break

        case 'Otro':
          gender = 'others'
          break

        default:
          break
      }
      this.$emit('validatePrincipal', true)
      const principal = {
        fullname: this.nombreContacto,
        dni: this.dni,
        gender: gender,
      }
      this.$emit('principalData', principal)
    }
  }

  get nombreContactoErrors(): Array<string> {
    const errors: Array<string> = []

    if (!this.$v.nombreContacto.$dirty) return errors
    !this.$v.nombreContacto.required &&
      errors.push(ErrorMessages.COMMOM_FIELD_REQUIRED)
    return errors
  }

  get dniErrors(): Array<string> {
    const errors: Array<string> = []
    if (!this.$v.dni.$dirty) return errors
    !this.$v.dni.required && errors.push(ErrorMessages.COMMOM_FIELD_REQUIRED)
    return errors
  }

  get sexoErrors(): Array<string> {
    const errors: Array<string> = []
    if (!this.$v.sexo.$dirty) return errors
    !this.$v.sexo.required && errors.push(ErrorMessages.COMMOM_FIELD_REQUIRED)
    return errors
  }
}
