import axios from "@/services/base.service";

const user_token = localStorage.getItem("access_token");
const config = {
  headers: {
    Authorization: `Bearer ${user_token}`,
  },
};

export const getMpData = async (): Promise<any> => {
  const data = await axios.get(`/get-mp-url-for-seller`, config);
  return data;
};

export const unBindMp = async (): Promise<any> => {
  const data = await axios.get("/remove-mp-token-for-seller", config);
  return data;
};

export const generateQr = async (qrData) => {
  const data = await axios.post(
    `/sellers/store-dynamic-assistant-list`,
    qrData
  );
  return data;
};
