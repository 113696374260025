
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Validate } from 'vuelidate-property-decorators'
import { required } from 'vuelidate/lib/validators'
import { ErrorMessages } from '@/helpers/constants'
import { getUserByDni } from '@/services/seller.service'

@Component
export default class InvitadoContactForm extends Vue {
  @Prop() arrayId!: number

  @Validate({ required }) nombreInvitado!: string
  @Validate({ required }) dni!: string
  @Validate({ required }) sexo!: string
  usersArray = []
  mounted() {
    this.sexo = 'Otro'
  }

  rellenarinvitado(user) {
    this.$v.nombreInvitado.$model = user.fullname
    this.dni = user.dni

    switch (user.gender) {
      case 'male':
        this.sexo = 'Masculino'
        break

      case 'female':
        this.sexo = 'Femenino'
        break

      case 'others':
        this.sexo = 'Otros'
        break

      default:
        break
    }

    this.usersArray = []

    this.validate()
  }

  async searchContact() {
    if (this.dni.length >= 4) {
      const contact = await getUserByDni(this.dni)
      this.usersArray = contact.data.assistants
    }

    if (this.dni.length <= 3) {
      this.usersArray = []
    }
  }

  validate() {
    if (!this.nombreInvitado || !this.dni || !this.sexo) {
      this.$emit('validate', false)
    } else {
      this.$emit('validate', true)
      let gender
      switch (this.sexo) {
        case 'Masculino':
          gender = 'male'
          break

        case 'Femenino':
          gender = 'female'
          break

        case 'Otro':
          gender = 'others'
          break

        default:
          break
      }

      let id = 0
      const invitado = {
        fullname: this.nombreInvitado,
        dni: this.dni,
        gender: gender,
        id: this.arrayId,
      }
      this.$emit('invitadoData', invitado)
    }
  }

  async createQr() {
    const contactForm = {
      contactName: this.nombreInvitado,
      dni: this.dni,
      sexo: this.sexo,
    }
  }

  get nombreInvitadoErrors(): Array<string> {
    const errors: Array<string> = []

    if (!this.$v.nombreInvitado.$dirty) return errors
    !this.$v.nombreInvitado.required &&
      errors.push(ErrorMessages.COMMOM_FIELD_REQUIRED)
    return errors
  }

  get dniErrors(): Array<string> {
    const errors: Array<string> = []
    if (!this.$v.dni.$dirty) return errors
    !this.$v.dni.required && errors.push(ErrorMessages.COMMOM_FIELD_REQUIRED)
    return errors
  }

  get sexoErrors(): Array<string> {
    const errors: Array<string> = []
    if (!this.$v.sexo.$dirty) return errors
    !this.$v.sexo.required && errors.push(ErrorMessages.COMMOM_FIELD_REQUIRED)
    return errors
  }
}
